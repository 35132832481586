import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal';


import ExhibitionList from '../components/ExhibitionListFront'
import CollectionHighlights from '../components/CollectionHighlights'
import SectionHead from '../components/SectionHead'

export const FrontTemplate = ({ content }) => {
  return (
    <section className="hero is-medium">
      <SectionHead title={content.front_intro}/>
      <div className="columns is-multiline is-vcentered">
        <Reveal effect="fadeInUpSlow">
          <div className="column is-12-tablet is-7-desktop hero__image">
            <Img fluid={content.image.localFile.childImageSharp.fluid} key={content.image.id}/>
          </div>
          <div className="hero__content column is-10-mobile is-offset-1-mobile is-12-tablet is-4-desktop is-5-widescreen">
            <div className="columns is-multiline">
              <div className="column is-offset-1-tablet is-5-tablet is-11-desktop is-9-widescreen is-offset-1-desktop">
                <h2 className="is-size-2 has-font-weight-light">{ content.front_content.headline }</h2>
              </div>
              <div
                className="column is-9-desktop is-6-widescreen is-5-tablet is-offset-1-desktop"
                dangerouslySetInnerHTML={{ __html: content.front_content.text }}
              />
              </div>
          </div>
        </Reveal>
      </div>
    </section>
  )
}

FrontTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object,
  data: PropTypes.shape({
    allWordpressWpExhibitions: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const Page = ({ data }) => {

  const { wordpressPage: page } = data
  const { edges: exhibitions } = data.allWordpressWpExhibitions
  const { edges: artists } = data.allWordpressWpArtists

  return (
    <div className="front-page">
      <Helmet title={`Q Contemporary`} />
      <FrontTemplate title={page.title} content={page.acf} />
      <ExhibitionList posts={exhibitions} range="current" full="true" title="Current exhibitions"/>
      <CollectionHighlights posts={artists} title="Collection highlights"/>
    </div>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query FrontQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      acf {
        image {
          id
          localFile {
            childImageSharp {
                # Try editing the "maxWidth" value to generate resized images.
                fluid(maxWidth: 1680) {
                  ...GatsbyImageSharpFluid
                }
            }
          }
        }
        front_content {
          headline
          text
        }
        front_intro
      }
    }
    allWordpressWpExhibitions(
        sort: { order: [ASC, ASC], fields: [acf___start_date, menu_order] }, filter: {slug: {ne: "do-not-delete"}}
      ) {
        edges {
          node {
            ...ExhibitionListFields
          }
        }
    }
    allWordpressWpArtists(
      filter: {acf: {artworks_: {elemMatch: {info: {visibility: {in: true}}}}}}
    ) {
      edges {
        node {
          ...CollectionHighlightsFields
        }
      }
    }
  }
`
