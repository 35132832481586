import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ArtworkSlider from './ArtworkSlider'
import SectionHead from './SectionHead'
import Reveal from 'react-reveal/Reveal';


export default class CollectionHighlights extends React.Component {
  render() {
    const { posts, title } = this.props
    return (
      <section className="ch is-fullheight">
        <SectionHead title={title} link="See all artists" url="/collection" />
        <div className="container">
          <Reveal effect="fadeInUp">
            <ArtworkSlider slides={posts} />
          </Reveal>
        </div>
      </section>
    )
  }
}

CollectionHighlights.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment CollectionHighlightsFields on wordpress__wp_artists {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    acf {
      artworks_ {
        info {
          title
          description
          visibility
        }
        image {
            localFile {
                childImageSharp {
                    # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1680) {
                      ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
    }
  }
`
