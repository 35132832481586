import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Swipeable } from 'react-swipeable'

import { Transition, animated } from 'react-spring/renderprops'

function createSlide(slides, index) {
    return (
        <div className="slide columns is-multiline is-centered scroll-snap-center" style={{ width: '100%', height: '100%' }}>
            <div className="column is-9-tablet is-12-desktop slide__image">
              <div className="slide__container">
                  <Img style={{ maxHeight: '70vh', width: '100%', marginBottom:'100px'}} fluid={slides[index].node.acf.artworks_[0].image.localFile.childImageSharp.fluid} key={slides[index].node.acf.id}/>
                  <div className="slide__caption column is-6">
                    
                    <Link className="has-text-primary" to={ "/collection/" + slides[index].node.slug}>
                        {slides[index].node.title}
                    </Link><br/>
                    <i className="caption">{slides[index].node.acf.artworks_[0].info.title}</i><br/>

                    <p className="caption"dangerouslySetInnerHTML={{ __html: slides[index].node.acf.artworks_[0].info.description }} />
                    <p className="slider__swipeable-inline">Swipe to see more</p>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default class ArtworkSlider extends React.Component {
  state = {
    index: 0,
    comingFrom: { opacity: 0, position: "absolute", transform: "translate3d(100%, 0 ,0)" },
    leavingTo: { opacity: 0, position: "absolute", transform: "translate3d(-50%, 0, 0)" }
  };
  nextSlide = e =>
    this.setState(state => ({
      index: state.index === this.props.slides.length - 1 ? 0 : state.index + 1,
      comingFrom: { opacity: 0, position: "absolute", transform: "translate3d(100%, 0 ,0)" },
      leavingTo: { opacity: 0, position: "absolute", transform: "translate3d(-50%, 0, 0)" }
    }));
  prevSlide = e =>
    this.setState(state => ({
      index: state.index === 0 ? this.props.slides.length - 1 : state.index - 1,
      comingFrom: { opacity: 0, position: "absolute", transform: "translate3d(-50%, 0 ,0)" },
      leavingTo: { opacity: 0, position: "absolute", transform: "translate3d(100%, 0, 0)" }
    })
    );
  render() {
    const { index, comingFrom, leavingTo } = this.state;
    return (
      <div className="collection-highlights">
        <div className="columns is-4 slider__nav next" onClick={this.nextSlide}>Next</div>
        <div className="columns is-4 slider__nav prev" onClick={this.prevSlide}>Previous</div>
          <Swipeable
            className="slider"
            trackMouse
            preventDefaultTouchmoveEvent
            onSwipedLeft={this.nextSlide}
            onSwipedRight={this.prevSlide}
          >
            <Transition
              native
              reset
              unique
              items={index}
              from={comingFrom}
              enter={{ opacity: 1, position: "relative", transform: "translate3d(0%, 0, 0)" }}
              leave={leavingTo}
            >
              {index => style => (
                <animated.div className="animated" style={{ ...style }}>
                  { createSlide(this.props.slides, index) }
                </animated.div>
              )}
            </Transition>
          </Swipeable>
      </div>
    );
  }
}

ArtworkSlider.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object),
}